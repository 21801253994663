#card-inner-1 {
  border: 1px;
  border-color: #ededed;
  border-style: solid;
  box-shadow: none;
  margin-bottom: 10px;
}

.card-title,
.card-text {
  margin-left: -5px;
}

.list-group-item:hover {
  background-color: #d3d3d3;
  // color: #ededed;
}

.card-text {
  font-size: 14px;
}

a {
  color: #292b2c;
  text-decoration: none;
}

#card-inner-1:hover {
  color: #292b2c;
  cursor: pointer;
  background-color: #d3d3d3;
}
