.sidebar-menu {
  .setup-banner {
    background-color: #dc3545;
    text-align: center;
    color: #f8f9fa;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.setup-banner {
  background-color: #dc3545;
  text-align: center;
  color: #f8f9fa;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
}

/* SETUP SLIDER */
.slider-setup {
  position: fixed;
  left: -320px;
  top: 0;
  height: 100vh;
  width: 320px;
  background: #f9f9f9;
  z-index: 998;
  box-shadow: -5px 0 20px rgba(0, 0, 0, 0.05);
  -webkit-transition: left 0.3s cubic-bezier(0.62, -0.01, 0.2, 0.83) 0s;
  transition: left 0.3s cubic-bezier(0.62, -0.01, 0.2, 0.83) 0s;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #d7e1e5;
}

.show_hide.slider-setup {
  left: 0;
}

.slider-setup-close {
  font-size: 19px;
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.slider-setup-close:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.slider-setup-menu-tab {
  padding: 30px 20px;
}

.slider-setup-menu-tab li {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.slider-setup-menu-tab li a {
  display: block;
  font-size: 16px;
  color: #444;
  font-weight: 600;
  font-family: "lato", sans-serif;
  position: relative;
  padding-bottom: 15px;
}

.slider-setup-menu-tab li a.active {
  color: #2a3439;
}

.slider-setup-menu-tab li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: #2a3439;
  border-radius: 30px;
  -webkit-transition: all 0.3s cubic-bezier(0.91, 0.31, 0.31, 0.92) 0s;
  transition: all 0.3s cubic-bezier(0.91, 0.31, 0.31, 0.92) 0s;
}

.slider-setup-menu-tab li a.active:before {
  left: 0;
}

.slider-setup-menu-tab li a:before {
  left: 100%;
}

.slider-setup-menu-tab li:last-child a:before {
  display: none !important;
}

.slider-setup-content {
  padding: 15px;
}

.slider-setup-items {
  padding-top: 7px;
  padding-left: 53px;
}

.slider-setup-settings h4 {
  font-family: "lato", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 700;
  color: #444;
  margin-bottom: 23px;
}

.s-swtich input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.s-swtich label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 23px;
  background: #1bd4a6;
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0;
}

.s-swtich label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.s-swtich input:checked + label {
  background: #bada55;
}

.s-swtich input:checked + label:after {
  left: calc(100% - 5px);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.s-swtich label:active:after {
  width: 40px;
}

.s-settings {
  margin-bottom: 30px;
}

.s-settings p {
  color: #888383;
  font-size: 14px;
}

.s-sw-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.s-sw-title h5 {
  font-size: 16px;
  color: #555;
}


// @author vishal
.trial-summary{
  background-color: #5cb85c;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-bottom: 15px;

  a{
    color:#fff;
  }
}
