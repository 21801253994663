.page-organisation {
  .form-buttons-group {
    justify-content: center;
    display: flex;
    margin-top: 5px;
    button {
      margin-right: 5px;
    }
  }
}

.StripeElement {
  // border-radius: 0.25rem;
  // background-color: #e9ecef;
  // opacity: 1;
  // font-size: 14px;
  // border: 1px solid rgba(170, 170, 170, 0.3);
  // padding: 0.77rem 0.8rem;
}
