@import "sidebar";
@import "custom";
@import "organisation";

.year-select {
  width: 80px;
  margin-left: 16px;
  height: 44px !important;
}

@media only screen and (max-width: 400px) {
  .year-select {
    margin-left: 0px !important;
  }
}

.month-select {
  height: 44px !important;
  width: 80px;
  margin-left: 4px;
}
.day-select {
  height: 44px !important;
  margin-left: 4px;
  width: 68px;
}

#dropdown-date {
  width: 300px !important;
}

.date-picker-input {
  margin-left: 105px;
  margin-top: -3px;
  width: 80px;
  height: 45px;
  border: 1px solid rgb(206, 212, 218) !important;
  padding: 9px 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.date-picker-input-reports {
  margin-left: 82px;
  margin-top: 7px;
  width: 120px;
  height: 35px;
  border: 1px solid rgb(206, 212, 218) !important;
  padding: 9px 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.date-picker-mark-as-read {
  margin-left: 105px;
  margin-top: -3px;
  // width: px;
  height: 45px;
  border: 1px solid rgb(206, 212, 218) !important;
  padding: 9px 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filter-span {
  // width: 95px;
  font-size: 13px;
}

.date-span {
  height: 45px;
  width: 87px;
}

.action_new_button {
  width: 105px !important;
  margin: 0;
}

.react-autosuggest__suggestion--first {
  background: #dddddd;
}

@media (min-width: 768px) {
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 65.666667%;
    max-width: 50.666667%;
  }
}

@media (max-width: 320px) {
  .modal-dialog {
    width: 300px !important;
  }
}

@media (max-width: 768px) {
  .customer-new-area .show_hide {
    width: 100vw !important;
  }
}

.top-content {
  position: relative;
  top: -5px;
}

#card-customer-invoice .card {
  width: 96%;
  margin: auto;
}

button:disabled {
  cursor: not-allowed;
}

// .editor-class {
//   height: 300px;
// }

.terms_and_conditions {
  padding: 20px 20px;
  margin: 20px 20px;
}

.rdw-editor-main {
  height: 250px;
}

@media only screen and (min-width: 767px) {
  .rdw-editor-main {
    height: 200px;
  }
}
