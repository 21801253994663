#cc-signature {
  text-align: center;
  font-size: 14px !important;
  padding: 50px 0px;
}

h3 {
  text-align: left;
}

#button-action {
  margin: -10px -20px -25px 20px;
  float: right;
}

#status-text {
  margin-left: -10px;
}

#form-status {
  background-color: #d9534f;
}

#form-status h5 {
  color: white;
}

#form-content {
  margin-bottom: 15px;
}

.table-text-left {
  text-align: left;
}

.table-text-right {
  text-align: right;
}

#card-customer-invoice-archived {
  margin: 0 auto;
}

#card-receipt {
  margin: 0 auto;
}

@media print {
  #form-status {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }

  #card-customer-invoice {
    top: 50px;
  }

  table,
  tr,
  td,
  tbody,
  thead {
    font-size: 0.8rem;
  }

  #form-status {
    position: fixed;
    width: 96%;
    z-index: 1;
    top: 10px;
  }

  #test-table {
    display: table;
    // display: block;
    width: 99%;
  }
  #test-table th:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
  #test-table td:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
}

h3 {
  text-align: left;
}

#button-action {
  margin: -10px -20px -25px 20px;
  float: right;
}

#status-text {
  margin-left: -10px;
}

#form-status-pending {
  background-color: #0275d8;
}

#form-status-pending h5 {
  color: white;
}

#form-content {
  margin-bottom: 15px;
}

.table-text-left {
  text-align: left;
}

.table-text-right {
  text-align: right;
}

#card-customer-invoice-archived {
  margin: 0 auto;
}

#card-receipt {
  margin: 0 auto;
}

@media print {
  #form-status-pending {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }

  #card-customer-invoice {
    top: 50px;
  }

  table,
  tr,
  td,
  tbody,
  thead {
    font-size: 0.8rem;
  }

  #form-status-pending {
    position: fixed;
    width: 96%;
    z-index: 1;
    top: 10px;
  }

  #test-table {
    display: block;
    width: 99%;
  }
  #test-table th:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
  #test-table td:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
}

h3 {
  text-align: left;
}

#button-action {
  margin: -10px -20px -25px 20px;
  float: right;
}

#status-text {
  margin-left: -10px;
}

#form-status-declined {
  background-color: #d9534f;
}

#form-status-declined h5 {
  color: white;
}

#form-content {
  margin-bottom: 15px;
}

.table-text-left {
  text-align: left;
}

.table-text-right {
  text-align: right;
}

#card-customer-invoice-archived {
  margin: 0 auto;
}

#card-receipt {
  margin: 0 auto;
}

@media print {
  #form-status-declined {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }

  table,
  tr,
  td,
  tbody,
  thead {
    font-size: 0.8rem;
  }

  #card-customer-invoice {
    top: 50px;
  }

  #form-status-declined {
    position: fixed;
    width: 92%;
    z-index: 1;
    top: 10px;
  }

  #test-table {
    display: block;
    width: 99%;
  }
  #test-table th:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
  #test-table td:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
}

h3 {
  text-align: left;
}

#button-action {
  margin: -10px -20px -25px 20px;
  float: right;
}

#status-text {
  margin-left: -10px;
}

#form-status-accepted {
  background-color: #5cb85c;
}

#form-status-accepted h5 {
  color: white;
}

#form-content {
  margin-bottom: 15px;
}

.table-text-left {
  text-align: left;
}

.table-text-right {
  text-align: right;
}

#card-customer-invoice-archived {
  margin: 0 auto;
}

#card-receipt {
  margin: 0 auto;
}

@media print {
  #form-status-accepted {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }

  #card-customer-invoice {
    top: 50px;
  }

  table,
  tr,
  td,
  tbody,
  thead {
    font-size: 0.8rem;
  }

  #form-status-accepted {
    position: fixed;
    width: 92%;
    z-index: 1;
    top: 10px;
  }

  #test-table {
    display: block;
    width: 99%;
  }
  #test-table th:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
  #test-table td:nth-child(n + 2):nth-child(-n + 4) {
    display: none;
  }
}

.proposal-head {
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.proposal-view {
  p {
    font-size: 14px !important;
  }

  .no-border {
    border: none !important;
  }
}


.card-approved,
.card-pending {
  background-color: #0275d8;
  color: #f7f7f7;
}

.card-accepted {
  background-color: #5cb85c;
  color: #f7f7f7;
}

.card-declined {
  background-color: #d9534f;
  color: #f7f7f7;
}
