#card-inner-reports {
  border: 1px;
  border-color: #ededed;
  border-style: solid;
  box-shadow: none;
  margin-bottom: 10px;
}

.card-title,
.card-text {
  margin-left: -5px;
}

.list-group-item:hover {
  // background-color: #d3d3d3;
  color: #292b2c;
}

.card-text {
  font-size: 14px;
}
